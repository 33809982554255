import { View, Text, ScrollView } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ArrowRightIcon } from 'react-native-heroicons/outline'
import MenuItemCard from './MenuItemCard'
import axios from 'axios'
import { environment } from '../environment/environment'

import "../styles.css";
import Loader from './Loader'

const MenuItems = () => {
    const [menuItems, setMenuItems] = React.useState([]);

  useEffect(() => {
    axios.get(environment.apiUrl + 'MenuManagement/getmenuitems')
      .then(response => {
        console.log(response.data);
        setMenuItems(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <View>
      <h1 className="text-xl text-white px-4">Restaurant Screen Placeholder</h1>
    <ScrollView>
      {menuItems.length == 0?<Loader/> : menuItems?.map(menuItem => (
        <MenuItemCard
          key={menuItem.id}
          id={menuItem.id}
          imgSrc={menuItem.menuItemImage}
          address={''}
          title={menuItem.name}
          dishes={''}
          short_description={''}
        />
      ))}

    </ScrollView>
    </View>
  )
}

export default MenuItems