import React, { useState } from "react";
import {
  View,
  TextInput,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { PaperAirplaneIcon } from "react-native-heroicons/outline";

import "../styles.css";
const SendInvoice = (props) => {
  const { restaurant, orderDate, orderNumber, orderItems, orderTotal } = props;
  const [userEmail, setUserEmail] = useState("");
  const [isSending, setIsSending] = useState("");

  const emailJsCreds = {
    service_id: "service_2t4ov4x",
    template_id: "template_q9u86yb",
    userID: "mdZb2pgScuGxjbLz1",
  };

  const showToast = (message) => {
    toast(message);
  };



  const sendInvoiceEmail = () => {
    console.log("Order Items: ", orderItems);
    setIsSending(true);
    const templateParams = {
      restaurant_name: restaurant,
      user_email: userEmail,
      order_date: orderDate,
      order_number: orderNumber,
      order_items: orderItems,
      order_total: orderTotal,
    };

    emailjs
      .send(
        emailJsCreds.service_id,
        emailJsCreds.template_id,
        templateParams,
        emailJsCreds.userID
      )
      .then((response) => {
        console.log(templateParams);
        setIsSending(false);
        showToast("Thank you! Invoice has been sent");
      })
      .catch((error) => {
        showToast("Error sending invoice");
        setIsSending(false);
        console.error("EmailJS Error:", error);
      });
  };

  return (
    <View className="px-2 flex-row items-center space-x-1">
      <Text className="text-md text-gray-500">
        <TextInput
          style={styles.input}
          onChangeText={setUserEmail}
          value={userEmail}
          placeholder="Email for order invoice"
          keyboardType="email-address"
        />
      </Text>
      {isSending ? (<ActivityIndicator size="small" color="#E75300" />
      ) : (
      <TouchableOpacity
        onPress={sendInvoiceEmail}
        className="rounded-full px-3 pt-2"
      >
        <PaperAirplaneIcon color="#E75300" height={20} width={20} />
      </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  input: {
    width: "100%",
    padding: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    color: "black",
  },
});

export default SendInvoice;


