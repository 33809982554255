import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react'

import "../styles.css";

const CategoryCard = ({ imgUrl, title }) => {
  return (
    <TouchableOpacity className="mr-2 relative"> 
    <Image 
        source={{
            uri: imgUrl,
        }}
        className='h-20 w-20 '
    />
      <Text className="absolute bottom-1 left-1 text-white font-bold bg-black/50">{title}</Text>
    </TouchableOpacity>
  )
}

export default CategoryCard