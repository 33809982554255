import { View, Text, TouchableOpacity } from 'react-native';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBasketItems} from '../features/basketSlice';
import { useNavigation } from '@react-navigation/native';
import { ShoppingCartIcon } from "react-native-heroicons/outline";
import "../styles.css"; 

const BasketIcon = () => {
    const items = useSelector(selectBasketItems);
    const navigation = useNavigation();

    if (items.length === 0) return null;

    return (
        <View className="absolute top-14 right-5 p-2 bg-gray-100 rounded-full">
            <TouchableOpacity onPress={() => navigation.navigate('Basket')}>
                <ShoppingCartIcon size={20} color="#E75300" />
                <View style={{ 
                    position: 'absolute', 
                    top: -9, 
                    right: -10, 
                    backgroundColor: 'rgb(231, 83, 0)', 
                    borderRadius: 10, 
                    padding: 2, 
                    minWidth: 20, 
                    alignItems: 'center' 
                }}>
                    <Text style={{ color: 'white', fontSize: 10 }}>{items.length}</Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export default BasketIcon;