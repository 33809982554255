import { View, Text, TouchableOpacity, Image } from "react-native";
import React from "react";
import { MapPinIcon } from "react-native-heroicons/solid";
import { urlFor } from "../sanity";
import { useNavigation } from "@react-navigation/native";

import "../styles.css";

const RestaurantCard = ({
  id,
  imgSrc,
  title,
  short_description,
  area,
  province,
  address,
  long,
  lat,
  categories,
  dishes,
}) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate("Restaurant", { id });
      }}
      className="bg-white mr-3 shadow"
      style={{ borderRadius: 10, margin: 10 }}
    >
      <Image
        source={{uri: `${imgSrc}`,}}
        className="w-full h-40 rounded-lg"
      />
      <View className="px-3 pb-4">
        <Text className="font-bold text-lg pt-2">{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default RestaurantCard;
