import {
  View,
  Text,
  SafeAreaView,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { React, useLayoutEffect, useEffect, useState, useRef } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  UserIcon,
  ChevronDownIcon,
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from "react-native-heroicons/outline";
import Categories from "../components/Categories";
import FeaturedRow from "../components/FeaturedRow";
import MenuItems from "../components/MenuItems";
import sanityClient from "../sanity";
import Welcome from "../assets/images/welcome.png";

import "../styles.css";
import Restaurants from "../components/Restaurants";
import { Input } from "@mui/material";
import { Button } from "react-native-paper";
import QRCodeScanner from "../components/qrScanner";

const HomeScreen = () => {
  const navigation = useNavigation();
  const [featuredCategoies, setFeaturedCategories] = useState([]);
  const [menuItems, setItems] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const route = useRoute();
  const ref = useRef(null);
  const [height, setHeight] = useState(130);
  const inputElement = useRef();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  useLayoutEffect(() => {
    const { innerHeight: height } = window;
    setHeight(innerHeight);
  }, []);

  const openCamera = () => {
    setShowCamera(true);
  };

  return (
    <SafeAreaView
      className="bg-black pt-5"
      ref={ref}
      style={{ height: height }}
    >
      {/* Header */}
      <meta name="apple-mobile-web-app-capable" content="yes"></meta>
      <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>

      <div
        style={{ display: "flex", justifyContent: "center"}}
      >    
          <img src={Welcome} style={{width: "300px", height: "auto", position: "relative"}} />
        </div>
      <View
        className="container max-w-screen-xl mx-auto px-4"
        style={{ color: "white" }}
      >
      {!showCamera ?
        <div className="text-center">
          <div className="flex justify-center mb-16"></div>

          <h1 className="font-normal text-white text-4xl md:text-7xl leading-none mb-8">
            Ordering made easy
          </h1>

          <p className="font-normal text-white text-md md:text-xl mb-16">
            To start your ordering process please scan the restaurant QR code
          </p>
        </div> : <></> }
      </View>
      <View className="mt-10">
        {!showCamera ? (
          <TouchableOpacity
            onPress={openCamera}
            className="bg-[#E75300] mx-5 p-4 rounded-lg flex-row items-center space-x-1"
          >
            <Text className="flex-1 text-white font-extrabold text-lg text-center">
              Scan QR Code
            </Text>
          </TouchableOpacity>
        ) : (
          <></>
        )}

        {showCamera ? <QRCodeScanner /> : <></>}
      </View>
      <input
        hidden={true}
        ref={inputElement}
        id="scan"
        type="file"
        accept="image/*"
        capture="camera"
      />
    </SafeAreaView>
  );
};

export default HomeScreen;
