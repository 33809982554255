import { View, Text, SafeAreaView } from 'react-native';
import React, { useEffect } from 'react';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';
import * as Progress from 'react-native-progress';
import Welcome from '../assets/images/welcome.png';

import "../styles.css";


const PreparingOrderScreen = () => {
    const navigation = useNavigation();

    useEffect(() => {
        // After 5 secs naviagte to the next screen
        setTimeout(() => {
            navigation.navigate('CollectionScreen');
        }, 4000);
    }, [])
    
  return (
    <SafeAreaView className="bg-[#E57300] flex-1 justify-center items-center">
      <Image
       source={Welcome}
       animation="slideInUp"
       iterationCount={1}
       className="h-80 w-80"
        />

        <Text
            className="text-lg my-10 text-white font-bold text-center"
        >
            Waiting for Restaurant to accept your order!
        </Text>

        <Progress.Circle size={60} indeterminate={true} color="#fff" />
    </SafeAreaView>
  )
}

export default PreparingOrderScreen