import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import {  MapPinIcon } from 'react-native-heroicons/solid';
import { urlFor } from '../sanity';
import { useNavigation } from '@react-navigation/native';

import "../styles.css";

const FeaturedCard = ({
    id,
    imgUrl,
    title,
    short_description,
    area,
    province,
    address,
    long,
    lat,
    categories,
    dishes,
}) => {
  const navigation = useNavigation();
  
    return (
    <TouchableOpacity 
     onPress={() => {
         navigation.navigate('Dish', {
            id,
            imgUrl,
            title,
            short_description,
            area,
            province,
            address,
            long,
            lat,
            dishes,
         })
     }}   
     className="bg-white mr-3 shadow">
      <Image 
        source={{
            uri: urlFor(imgUrl).url(),
            }}
            className="w-64 h-40 rounded-sm"
        />
        <View className="px-3 pb-4">
            <Text className="font-bold text-lg pt-2">{title}</Text>
            {/* <View className="flex-row items-center space-x-1">
                <StarIcon color="green" opacity={0.5} size={22} />
                <Text className="text-xs text-gray-500">
                <Text className="text-green-500">{rating}</Text> 
                </Text>
            </View> */}
            <View className="flex-row space-x-1">
                <MapPinIcon color='gray' opacity={0.4} size={22}/>
                <Text className="text-xs text-gray-500 pt-1">
                Nearby • {address}
                </Text>
            </View>
        </View>
    </TouchableOpacity>
  )
}

export default FeaturedCard