import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  Image,
  ScrollView,
} from "react-native";
import { React, useEffect, useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { selectFavorite } from "../features/restaurantSlice";
import { XCircleIcon, TrashIcon } from "react-native-heroicons/outline";
import * as Progress from "react-native-progress";
import { environment } from "../environment/environment";
import axios from "axios";
import Currency from "react-currency-formatter";
import SendInvoice from "../components/SendInvoice";

import "../styles.css";

const OrderScreen = () => {
  const navigation = useNavigation();
  const favorite = useSelector(selectFavorite);
  const [order, setOrder] = useState([]);
  const [orderDate, setOrderDate] = useState([]);

  const {
    params: { orderId },
  } = useRoute();

  useEffect(() => {
    axios
      .get(`${environment.apiUrl}OrderManagement/getorder`, {
        params: {
          orderId: orderId,
        },
      })
      .then((response) => {
        console.log("response.data......");
        console.log(response.data);
        var date = new Date(response.data.orderDate);
        date.setHours(date.getHours() + 2);
        setOrderDate(
          date.toLocaleDateString() + " " + date.toLocaleTimeString()
        );
        setOrder(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getMenuItemExtras = (item) => {

    return item.length === 0 ? "" : item.selectedOptions.map(
      (itemExtras) => `<li>${itemExtras.name}</li>`
    ).join("");
  };

  return (
    <SafeAreaView className="flex-1 bg-white">
      <View className="flex-1 bg-gray-100">
        <View className="p-5 border-b border-[#E75300] bg-white shadow-xs">
          <View>
            <Text className="text-lg font-bold text-center">Order</Text>
            <Text className="text-center text-gray-400">QFlow</Text>
          </View>

          <TouchableOpacity
            onPress={() => navigation.navigate("Home")}
            className="rounded-full bg-gray-100 absolute top-3 right-5"
          >
            <XCircleIcon color="#E75300" height={35} width={35} />
          </TouchableOpacity>
        </View>

        <View className="bg-white">
          <View className="px-2 pt-4" style={{ marginBottom: "10px" }}>
            <Text className="font-bold text-3xl">Order Details</Text>
            {order.restuarantName ? (
              <SendInvoice
                restaurant={order?.restuarantName}
                orderDate={orderDate}
                orderNumber={order?.orderNumber}
                orderItems={order?.orderContent.basketItems
                  .map(
                    (item) =>
                      `<li>${item.name}, Price: R${item.price} </li><ul>${getMenuItemExtras(item)}</ul>`
                  )
                  .join("")}
                orderTotal={"R" + order?.amountInCents / 100}
              />
            ) : (
              <></>
            )}
            <View className="flex-row my-1 space-x-2">
              <View className="px-2 flex-row items-center space-x-1">
                <Text className="text-md text-gray-500">
                  <Text className="font-bold">{order?.restuarantName}</Text>
                </Text>
              </View>
            </View>
            <View className="flex-row my-1 space-x-2">
              <View className="px-2 flex-row items-center space-x-1">
                <Text className="text-md text-gray-500">
                  <Text>Order No: </Text>
                  <Text>{order?.orderNumber}</Text>
                </Text>
              </View>
            </View>
            <View className="flex-row my-1 space-x-2">
              <View className="px-2 flex-row items-center space-x-1">
                <Text className="text-md text-gray-500">
                  <Text>Order Date: </Text>
                  <Text>{orderDate ? orderDate : ""}</Text>
                </Text>
              </View>
            </View>
            {order ? (
              <>
              <View className="flex-row my-1 space-x-2">
                <View className="px-2 flex-row items-center space-x-1">
                  <Text className="text-md text-gray-500">
                    <Text>Tip amount: </Text>
                    <Currency
                      quantity={
                        order?.orderContent?.tip ? order?.orderContent.tip : 0.0
                      }
                      currency="ZAR"
                    />
                  </Text>
                </View>
              </View>
              {order?.orderContent?.discountValue ? 

            <View className="flex-row my-1 space-x-2">
              <View className="px-2 flex-row items-center space-x-1">
                <Text className="text-md text-gray-500">
                  <Text>Discount used: </Text>
                  <Text>{order?.orderContent?.discountUsed ? order?.orderContent.discountUsed : ''}</Text>
                </Text>
              </View>
            </View> : <></>
            }
            </>
            ) : (
              <></>
            )}
          </View>
          <TouchableOpacity className="flex-row items-center space-x-2 p-4 border-y border-gray-300">
            <Text className=" flex-1 text-xl font-bold">Order items</Text>
          </TouchableOpacity>
        </View>
        <ScrollView className="divide-y divide-gray-200">
          {order?.orderContent?.basketItems?.map((orderItem) => (
            <View
              key={orderItem.basketItemId}
              className="bg-white mr-3 shadow mt-2 ml-10"
              style={{ marginLeft: 10 }}
            >
              <View className="px-3 pb-4">
                <View>
                  <View className="flex-row items-center space-x-1">
                    <Text className="flex-1">
                      <Text className="font-bold text-lg pt-2">
                        {orderItem?.name}
                      </Text>
                    </Text>
                    <Text className="font-bold text-lg pt-2">
                      R{orderItem.price}
                    </Text>
                  </View>
                </View>
                {orderItem.selectedOptions.map((selectedOption) => (
                  <View key={selectedOption.id}>
                    <View className="flex-row items-center space-x-1">
                      <Text className="flex-1">
                        <Text className="text-lg pt-2">
                          {selectedOption.name}
                        </Text>
                      </Text>
                      <Text className="text-lg pt-2">
                        R{selectedOption.price}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
{order?.orderContent?.discountValue ? 
            <View
              className="bg-white mr-3 shadow mt-2 ml-10"
              style={{ marginLeft: 10 }}
            >
              <View className="px-3 pb-4">
                <View>
                  <View className="flex-row items-center space-x-1">
                    <Text className="flex-1">
                      <Text className="font-bold text-lg pt-2">
                        Discount applied
                      </Text>
                    </Text>
                    <Text className="font-bold text-lg pt-2">
                      -R{order?.orderContent?.discountValue}
                    </Text>
                  </View>
                </View>
              </View>
            </View> : <></>
}
        </ScrollView>
        {/*
          {Object.entries(groupedItemsInBasket).map(([key, items]) => (
            <View
              key={key}
              className="flex-row items-center space-x-3 bg-white py-2 px-5"
            >
             
              {items[0].selectedOptions.map((option) => (
                  <View>
                    <Text className="flex-1">{option?.name}</Text>
                  </View>
                ))}
            </View>
          ))}
        */}

        <View className="p-5 bg-white mt-5 space-y-4">
          <View className="flex-row items-center justify-between px-5 py-3 bg-white">
            <Text className="text-lg font-bold">Order Total</Text>
            <Text className="text-lg font-extrabold">
              <Currency quantity={order?.amountInCents / 100} currency="ZAR" />
            </Text>
          </View>
          {/* onPress={() => navigation.navigate("PreparingOrderScreen")} */}
        </View>
      </View>
    </SafeAreaView>
  );
};

export default OrderScreen;
