import React, { useEffect, useRef } from 'react';
import { View, Image, StyleSheet, Animated } from 'react-native';
import logo from '../assets/images/welcome.png'; // Adjust the path to your logo

const LoadingAnimation = () => {
  const scale = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    const pulsate = Animated.loop(
      Animated.sequence([
        Animated.timing(scale, {
          toValue: 1.5,
          duration: 1000,
          useNativeDriver: true,
        }),
        Animated.timing(scale, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
        }),
      ])
    );
    pulsate.start();

    return () => pulsate.stop();
  }, [scale]);

  const animatedStyle = {
    transform: [{ scale }],
  };

  return (
    <View style={styles.container}>
      <Animated.Image source={logo} style={[styles.logo, animatedStyle]} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000', // Assuming you want the background black
  },
  logo: {
    width: 100, // Adjust the size as needed
    height: 100, // Adjust the size as needed
  },
});

export default LoadingAnimation;
