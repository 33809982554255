import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";

const savedBasket = JSON.parse(localStorage.getItem('basket'));
let items = [];

if (savedBasket) {
  const oneDay = 3* 60 * 60 * 6 * 1000; // milliseconds in one day
  const currentTime = new Date().getTime();

  if (currentTime - savedBasket.timestamp <= oneDay) {
    items = savedBasket.items;
  } else {
    localStorage.removeItem('basket'); // clear the old data
  }
}


const initialState = {
  items: JSON.parse(localStorage.getItem("basket"))?.items || [],
};


export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addToBasket: (state, action) => {
      state.items = [...state.items, action.payload];
      localStorage.setItem("basket", JSON.stringify({items: state.items, timestamp: new Date().getTime()}));
      console.log('BasketAdd',JSON.stringify({items: state.items, timestamp: new Date().getTime()}))
      const currentItems = JSON.parse(localStorage.getItem("basket"))?.items;
      if(currentItems && currentItems.length > 0){
        const currentRest = currentItems.filter(i => i.restuarantId === action.payload.restuarantId);
        let newBasket = [...currentRest];
        state.items = newBasket;
        localStorage.setItem("basket", JSON.stringify({items: state.items, timestamp: new Date().getTime()}));
      }
    },

    removeFromBasket: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.basketItemId === action.payload.id
      );

      let newBasket = [...state.items];

      if (index >= 0) {
        newBasket.splice(index, 1);
      } else {
        console.warn(
          `Can't remove product (id: ${action.payload.id}) as its not in basket!`
        );
      }

      state.items = newBasket;
      localStorage.setItem("basket", JSON.stringify(state.items));
    },
    clearBasket:() =>{
      localStorage.removeItem('basket');
    }
  },
});

export const { addToBasket, removeFromBasket, clearBasket } = basketSlice.actions;

export const selectBasketItems = (state) => state.basket.items;

export const selectBasketItemsWithId = (state, id) =>
  state.basket.items.filter((item) => item.id === id);

export const selectBasketTotal = (state) =>
  state.basket.items.reduce((total, item) => (total += item.price), 0);

export default basketSlice.reducer;
