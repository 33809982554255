import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Alert } from 'react-native';
import axios from 'axios';
import { environment } from '../environment/environment';
import { toast } from "react-toastify";

const CouponComponent = ({ onApplyCoupon, serviceProviderId }) => {
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState('');

  const showToast = (message) => {
    toast(message);
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      setError('Please enter a coupon code');
      return;
    }
  
    setError('');
    try {
      const response = await axios.get(`${environment.apiUrl}DiscountManagement/getdiscount`, {
        params: {
          name: couponCode,
          serviceProviderId:serviceProviderId
        },
      });
  
      if (response.data.content !== null && response.data.content.isActive && new Date(response.data.content.endDate) >= new Date() && new Date(response.data.content.startDate) <= new Date()) {
        console.log('COUPON!!!!!!!', response.data);
        onApplyCoupon(response.data);
        showToast("Coupon applied successfully!");
      } else {
        setError('Invalid or expired coupon code');
      }
    } catch (error) {
      setError('Error checking coupon. Please try again.');
      console.error(error);
    }
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        placeholder="Enter coupon code"
        value={couponCode}
        onChangeText={setCouponCode}
        autoCapitalize="none"
      />
      {error ? <Text style={styles.error}>{error}</Text> : null}
      <TouchableOpacity onPress={handleApplyCoupon} style={styles.button}>
        <Text style={styles.buttonText}>Apply Coupon</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderColor: '#ccc',
  },
  input: {
    backgroundColor: '#f9f9f9',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    padding: 8,
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#E75300',
    borderRadius: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
    marginBottom: 5,
  },
});

export default CouponComponent;
