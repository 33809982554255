import {
  View,
  Text,
  SafeAreaView,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import axios from "axios";
import Icon from 'react-native-vector-icons/FontAwesome'; // Import Icon
import MenuCategory from "../components/MenuCategory";
import { environment } from "../environment/environment"; // Ensure this import is correct
import LoadingAnimation from '../components/LoadingAnimation'; // Adjust the path if necessary

import "../styles.css";

const RestaurantScreen = () => {
  const navigation = useNavigation();
  const [menuCategories, setMenuCategories] = useState([]);
  const ref = useRef(null);
  const [height, setHeight] = useState(250);
  const [windowHeight, setWindowHeight] = useState(250);
  const [restaurant, setRestaurant] = useState(null);
  const [restaurantOnline, setRestaurantOnline] = useState(false);
  const [sortOption, setSortOption] = useState('a-z'); // State for sorting option
  const [showFilters, setShowFilters] = useState(false); // State for filter visibility
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useLayoutEffect(() => {
    const { innerHeight: height } = window;
    setWindowHeight(innerHeight);
  }, []);

  const {
    params: { id },
  } = useRoute();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  useEffect(() => {
    axios
      .get(`${environment.apiUrl}RestaurantManagement/getrestaurantbyId`, {
        params: { restaurantId: id },
      })
      .then((response) => {
        setRestaurant(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${environment.apiUrl}RestaurantManagement/getrestaurantcategoriesnopaging`,
        {
          params: {
            restuarantId: id,
          },
        }
      )
      .then((response) => {
        setMenuCategories(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${environment.apiUrl}RestaurantManagement/getonlinestatus?${id}`,
        {
          params: {
            restaurantId: id,
          },
        }
      )
      .then((response) => {
        setRestaurantOnline(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Force loading animation to show for at least 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleSortChange = (option) => {
    setSortOption(option);
    setShowFilters(false); // Hide filters after selection
  };

  return (
    <SafeAreaView className="bg-black pt-5" style={{ minHeight: windowHeight }}>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <ScrollView>
          <View className="bg-black pt-5 sticky" ref={ref}>
            <View>
              {restaurant && (
                <View style={{ margin: 10, borderRadius: 10, overflow: "hidden" }}>
                  <Image
                    source={{ uri: restaurant.featuredImage }}
                    style={{ width: "100%", height: 200 }}
                    resizeMode="cover"
                  />
                </View>
              )}
            </View>
          </View>

          {!menuCategories && restaurantOnline ? (
            <LoadingAnimation />
          ) : menuCategories && restaurantOnline ? (
            <View style={{ marginTop: height, paddingLeft: "10px", paddingRight: "10px" }} className="bg-black">
              {menuCategories?.map((category) => (
                <MenuCategory
                  style={{ borderRadius: 10 }}
                  key={category.id}
                  id={category.id}
                  title={category.categoryName}
                  description={category.short_description}
                  sortOption={sortOption} // Pass the sort option to MenuCategory
                  setSortOption={handleSortChange} // Pass the handleSortChange function to MenuCategory
                />
              ))}
            </View>
          ) : (
            <View style={{ marginTop: height }} className="bg-black">
              {restaurantOnline === false && <LoadingAnimation />}
            </View>
          )}
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

const styles = {
  filterBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  filterBtnText: {
    color: 'black',
    fontWeight: 'bold',
    marginLeft: 5,
  },
  filterButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(231, 83, 0)',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  filterButtonText: {
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 5,
  },
  filterOptions: {
    marginTop: 10,
  },
  sortButton: {
    backgroundColor: 'rgb(231, 83, 0)',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
  },
  sortButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

export default RestaurantScreen;
