import React, { useEffect, useRef } from 'react';
import jsQR from 'jsqr';
import { useNavigation } from "@react-navigation/native";


const QRCodeScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const navigation = useNavigation()

  useEffect(() => {
    // Check for support
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Use facingMode: environment to attempt to get the back camera on phones
      const constraints = { video: { facingMode: "environment" } };

      navigator.mediaDevices.getUserMedia(constraints)
      .then(function(stream) {
        // Display the video stream
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        scanQRCode();
      })
      .catch(function(err) {
        console.error("Error accessing the camera", err);
        // Handle the error, show message to the user
      });
    } else {
      // Show a message to the user indicating their browser doesn't support getUserMedia
    }
    
  }, []);

  function containsSubstring(url, substring) {
    return url.includes(substring);
  }

  const scanQRCode = () => {
    const canvasElement = canvasRef.current;
    const video = videoRef.current;
    const context = canvasElement.getContext("2d");
    
    // Continuously capture frames from the video stream
    const scan = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        context.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        
        const imageData = context.getImageData(0, 0, canvasElement.width, canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        
        if (code) {
          console.log('QR Code detected:', code.data);
          if(containsSubstring(code.data,'https://qflow.co.za'))(
            window.location.href = code.data
          )
          else{
            console.log('not qflow qr')
          }
          // Stop video stream
          video.srcObject.getTracks().forEach(track => track.stop());
          // Do something with the QR code data
        } else {
          // Keep scanning
          requestAnimationFrame(scan);
        }
      } else {
        requestAnimationFrame(scan);
      }
    };
    scan();
  };


  return (
    <div>
   <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      <div style={{ width: '300px', height: '200px', position: 'relative', overflow: 'hidden' }}>
        <video ref={videoRef} style={{ width: '100%', height: 'auto' }} autoPlay={true} playsInline={true}></video>
      </div>
    </div>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      </div>
  );
};  

export default QRCodeScanner;