import { View, Text, ScrollView } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ArrowRightIcon } from 'react-native-heroicons/outline'
import FeaturedCard from './FeaturedCard'
import sanityClient from '../sanity'

import "../styles.css";

const FeaturedRow = ({ title, description, featuredCategory, id, address }) => {
    const [favorites, setfavorites] = React.useState([]);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "featured" && _id == $id] {
      ...,
      favorites[]->{
        ...,
        dishes[]->,
        type-> {
          name
        }
      },
    }[0]
    `, { id }
    ).then(data => {
      console.log(data?.favorites);
      setfavorites(data?.favorites);
    });
  }, [])

  return (
    <View>
      <View className="mt-4 flex-row items-center justify-between px-4">
        <Text className="font-bold text-lg">{title}</Text>
        <ArrowRightIcon color="#E75300"/>
      </View>
      <Text className="text-xs text-gray-500 px-4">{description}</Text>
    
    <ScrollView
      horizontal
      contentContainerStyle={{
        padddingHorizontal: 15,
      }}
      showsHorizontalScrollIndicator={false}
      className="pt-4"
    >
      {favorites?.map(favorite => (
        <FeaturedCard
          key={favorite._id}
          id={favorite._id}
          imgUrl={favorite.image}
          address={favorite.address}
          title={favorite.name}
          dishes={favorite.dishes}
          short_description={favorite.short_description}
        />
      ))}

    </ScrollView>
    </View>
  )
}

export default FeaturedRow