import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    favorite: {
        id: null,
        imgUrl: null,
        title: null,
        address: null,
        short_description: null,
        dishes: null,
        lat: null,
        long: null,
    }
};

export const restaurantSlice = createSlice({
    name: 'favorite',
    initialState,
    reducers: {
        setFavorite: (state, action) => {
            state.favorite = action.payload;
        },
    },
});

export const { setFavorite } = restaurantSlice.actions;

export const selectFavorite = state => state.favorite.favorite;

export default restaurantSlice.reducer;
