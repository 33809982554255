import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
} from "react-native";
import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import axios from "axios";
import { environment } from "../environment/environment";
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  // StarIcon,
} from "react-native-heroicons/outline";
import DishRow from "../components/DishRow";
import { urlFor } from "../sanity";
import BasketIcon from "../components/BasketIcon";
import { useDispatch } from "react-redux";
import { setFavorite } from "../features/restaurantSlice";
import cloneDeep from "lodash/cloneDeep";
import {
  addToBasket,
  selectBasketItems,
  selectBasketItemsWithId,
  removeFromBasket,
} from "../features/basketSlice";

import "../styles.css";
import { Button } from "@react-native-material/core";
import { v4 as uuid } from "uuid";

const DishScreen = () => {
  const [menuItem, setMenuItem] = useState();
  const [menuItemExtras, setMenuItemExtras] = useState();
  const [selectedOptions, setSelectedOption] = useState([]);
  const [menuItemPrice, setMenuItemPrice] = useState();
  const [requiredOptionsSelected, setRequiredOptionsSelected] = useState(false); // Default to false
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [topMargin, setTopMargin] = useState(320);

  const {
    params: { id },
  } = useRoute();

  useEffect(() => {
    axios
      .get(
        environment.apiUrl + "MenuManagement/getmenuitemwithextrasandoptions/",
        {
          params: {
            menuItemId: id,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setMenuItem(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (menuItem) {
      setMenuItemPrice(Number(menuItem.basePrice).toFixed(2));
      setMenuItemExtras(menuItem.menuItemExtraWithOptions);
    }
  }, [menuItem]);

  useEffect(() => {
    if (menuItemExtras) {
      setMenuItemExtras(menuItemExtras);
    }
  }, [setMenuItemExtras]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      checkRequiredOptionsSelected();
    } else if (menuItemExtras) {
      if (checkIfRequiredOptionsExist()) {
        setRequiredOptionsSelected(false);
      } else {
        setRequiredOptionsSelected(true);
      }
    }
  }, [selectedOptions, menuItemExtras]);

  const addItemToBasket = () => {
    var price = Number(menuItemPrice);
    var name = menuItem.name;
    var image = menuItem.menuItemImage;
    var description = menuItem.description;
    var restuarantId = menuItem.restuarantId;
    var basketItemId = uuid();
    dispatch(
      addToBasket({
        id,
        basketItemId,
        name,
        description,
        price,
        image,
        restuarantId,
        selectedOptions,
      })
    );
  };

  const setSelectedOptions = (extra, option, value) => {
    menuItemExtras
      .filter((m) => m.menuItemExtraId === extra.menuItemExtraId)[0]
      .menuItemExtraOptions.filter((o) => o.id === option.id)[0].isSelected =
      value;

    setMenuItemExtras(cloneDeep(menuItemExtras));
    const selectedMenuItemExtras = menuItemExtras
      .filter((m) => m.menuItemExtraId === extra.menuItemExtraId)[0]
      .menuItemExtraOptions.filter((o) => {
        return o.id === option.id && o.isSelected === true;
      });

    if (value === true) {
      setSelectedOption((currentOptions) => {
        return [...currentOptions, selectedMenuItemExtras[0]];
      });
    } else {
      setSelectedOption((currentOptions) => {
        return currentOptions.filter((o) => {
          return o.id !== option.id;
        });
      });
    }
  };

  const checkDisabled = (menuItemExtra, menuItemExtraOption) => {
    if (selectedOptions) {
      var extraOptionsSelected = 0;
      for (
        let index = 0;
        index < menuItemExtra.menuItemExtraOptions.length;
        index++
      ) {
        const extraOptionToCheck = menuItemExtra.menuItemExtraOptions[index];
        if (
          selectedOptions.filter((i) => i.id === extraOptionToCheck.id).length >
          0
        ) {
          extraOptionsSelected++;
        }
      }
      if (
        extraOptionsSelected >= menuItemExtra.optionsLimit &&
        selectedOptions.filter((i) => i.id === menuItemExtraOption.id)
          .length === 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const checkIfRequiredOptionsExist = () => {
    let hasRequiredOptions = false;
    for (let extraIndex = 0; extraIndex < menuItemExtras.length; extraIndex++) {
      const extraToCheck = menuItemExtras[extraIndex];

      if (extraToCheck.isRequired === true) {
        hasRequiredOptions = true;
      }
    }

    return hasRequiredOptions;
  };

  const checkRequiredOptionsSelected = () => {
    let listOfRequiredExtras = [];
    let listOfRequiredSelectedExtras = [];
    if (selectedOptions.length > 0) {
      for (
        let extraIndex = 0;
        extraIndex < menuItemExtras.length;
        extraIndex++
      ) {
        const extraToCheck = menuItemExtras[extraIndex];

        if (extraToCheck.isRequired === true) {
          listOfRequiredExtras.push(extraToCheck.menuItemExtraId);
          for (
            let extraOptionIndex = 0;
            extraOptionIndex < selectedOptions.length;
            extraOptionIndex++
          ) {
            if (extraToCheck.menuItemExtraOptions === undefined) {
            } else {
              const extraOptionToCheck = selectedOptions[extraOptionIndex];

              const optionIsPartOfRequiredExtra =
                extraToCheck.menuItemExtraOptions.filter(
                  (o) => o.id === extraOptionToCheck.id
                );

              if (optionIsPartOfRequiredExtra.length === 0) {
              } else if (
                extraToCheck.menuItemExtraOptions.filter(
                  (i) => i.id === extraOptionToCheck.id
                ).length > 0
              ) {
                console.log("selected", extraToCheck);
                listOfRequiredSelectedExtras.push(extraToCheck.menuItemExtraId);
              }
            }
          }
        }
      }
    }
    setRequiredOptionsSelected(
      includesAll(listOfRequiredSelectedExtras, listOfRequiredExtras)
    );
  };

  const includesAll = (arr, values) => values.every((v) => arr.includes(v));

  useEffect(() => {
    if (selectedOptions.length !== 0) {
      var totalOptionsPrice = selectedOptions.reduce(
        (total, item) => (total += item.price),
        0
      );
      setMenuItemPrice(
        Number((totalOptionsPrice += menuItem.basePrice)).toFixed(2)
      );
    } else if (menuItem) {
      setMenuItemPrice(Number(menuItem.basePrice).toFixed(2));
    }
  }, [selectedOptions]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);
  useEffect(() => {
    if (ref.current) {
      console.log("REF", ref.current.offsetHeight);
      setTopMargin(ref.current.offsetHeight);
    }
  }, [ref.current]);

  return menuItem ? (
    <>
      <View className="sticky" ref={ref}>
        <View className="relative">
          <Image
            source={{ uri: `${menuItem.menuItemImage}` }}
            className="w-full h-56 bg-gray-300 p-4"
          />
          <TouchableOpacity
            onPress={() => {
              const restaurantId = menuItem.restuarantId;
              if(navigation.canGoBack()){
                navigation.navigate("Restaurant", { id: restaurantId });
              }
              else{
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'Restaurant',params:{ id: restaurantId } }],
                });
              }
            }}
            className="absolute top-14 left-5 p-2 bg-gray-100 rounded-full"
          >
            <ArrowLeftIcon size={20} color="#E75300" />
          </TouchableOpacity>
          <BasketIcon />
        </View>

        <View className="bg-white">
          <View className="px-4 pt-4">
            <Text className="font-bold text-3xl">{menuItem.name}</Text>
            <Text className="text-gray-500 mt-2 pb-4">
              {menuItem.description}
            </Text>
          </View>
        </View>
      </View>
      <ScrollView className="bg-black" style={{ marginTop: topMargin }}>
        <View style={{ paddingTop: 10 }}>
          {menuItemExtras ? (
            menuItemExtras.map((menuItemExtra) => (
              <View
                key={menuItemExtra.menuItemExtraId}
                className="bg-white mr-3 shadow mt-2 ml-10"
                style={{ marginLeft: 10, borderRadius: "0.5rem" }}
              >
                <View className="px-3 pb-4">
                  <Text className="font-bold text-lg pt-2">
                    {menuItemExtra.name}
                  </Text>
                  {menuItemExtra.menuItemExtraOptions.map((extraOption) => (
                    <View key={extraOption.id} style={{ marginBottom: 3 }}>
                      <View key={extraOption.id}>
                        <View className="flex-1">
                          <TouchableOpacity
                            disabled={checkDisabled(menuItemExtra, extraOption)}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onPress={() => {
                              setSelectedOptions(
                                menuItemExtra,
                                extraOption,
                                !extraOption.isSelected
                              );
                            }}
                          >
                            <CheckBox
                              value={extraOption.isSelected}
                              disabled={checkDisabled(
                                menuItemExtra,
                                extraOption
                              )}
                              style={styles.checkbox}
                            />
                            <Text style={{ flex: 1, marginLeft: "10px" }}>
                              {extraOption.name}
                            </Text>
                            <Text style={{ marginRight: "0.5rem" }}>
                              {extraOption.price !== 0
                                ? "R" + extraOption.price
                                : ""}
                            </Text>
                            <br />
                          </TouchableOpacity>
                        </View>
                      </View>
                      {/* <View className="flex-row items-center space-x-1">
                        <Text className="flex-1">
                          <CheckBox
                            value={extraOption.isSelected}
                            disabled={checkDisabled(menuItemExtra, extraOption)}
                            onValueChange={(value) => {
                              setSelectedOptions(
                                menuItemExtra,
                                extraOption,
                                value
                              );
                            }}
                            style={styles.checkbox}
                          />
                          <Text className="pl-5">{extraOption.name}</Text>
                        </Text>
                        <Text className="flex-1">R{extraOption.price}</Text>
                      </View> */}
                    </View>
                  ))}
                </View>
              </View>
            ))
          ) : (
            <></>
          )}
        </View>

        <View className="mt-10">
          <TouchableOpacity
            onPress={addItemToBasket}
            className="mx-5 p-4 rounded-lg flex-row items-center space-x-1"
            disabled={!requiredOptionsSelected}
            style={{
              backgroundColor: requiredOptionsSelected ? "#E75300" : "gray",
            }}
          >
            <Text className="flex-1 text-white text-lg text-center">
              <Text style={{ float: "inline-start" }}>Add to basket</Text>
              <Text
                className="font-extrabold "
                style={{ float: "inline-end" }}
              >
                {menuItemPrice === "0.00" ? "" : "R " + menuItemPrice}
              </Text>
            </Text>
          </TouchableOpacity>
        </View>

        {/* <View className="pb-36">
          <Text className="px-4 pt-6 mb-3 font-bold text-xl">Menu</Text>
          {dishes.map((dish) => (
            <DishRow
              key={dish._id}
              id={dish._id}
              name={dish.name}
              description={dish.short_description}
              price={dish.price}
              image={dish.image}
            />
          ))}
        </View> */}
      </ScrollView>
    </>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
  button: {
    backgroundColor: "#d65d27",
    flex: 1,
    marginHorizontal: 8,
  },
});

export default DishScreen;
