import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../environment/environment";
import Loader from "./Loader";
import MenuItemCard from "./MenuItemCard";
import Collapsible from 'react-native-collapsible';
import {
  ChevronDownIcon,
  ChevronRightIcon
} from "react-native-heroicons/solid";
import Icon from 'react-native-vector-icons/FontAwesome'; // Import Icon

import "../styles.css";

const MenuCategory = ({
  title,
  description,
  id,
  sortOption, // Receive sortOption as prop
  setSortOption // Receive setSortOption as prop
}) => {
  const [menuItems, setMenuItems] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    axios
      .get(environment.apiUrl + "MenuManagement/getmenuitemsbycategoryid/", {
        params: {
          categoryId: id,
        },
      })
      .then((response) => {
        setMenuItems(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const sortMenuItems = (items, option) => {
    switch (option) {
      case 'a-z':
        return items.sort((a, b) => a.name.localeCompare(b.name));
      case 'z-a':
        return items.sort((a, b) => b.name.localeCompare(a.name));
      case 'price-asc':
        return items.sort((a, b) => a.basePrice - b.basePrice);
      case 'price-desc':
        return items.sort((a, b) => b.basePrice - a.basePrice);
      default:
        return items;
    }
  };

  const sortedMenuItems = sortMenuItems([...menuItems], sortOption);

  return menuItems ? (
    menuItems.length === 0 ? <></>
      :
      <View className="bg-black" style={{ marginBottom: 10 }}>
        <ScrollView className="bg-white" style={{ borderRadius: 10 }}>
          <TouchableOpacity onPress={() => setIsCollapsed(!isCollapsed)}>
            <h2 className="font-bold text-xl px-4" style={{ marginTop: '15px', marginBottom: '15px' }}>
              {title}
              {isCollapsed ? <ChevronRightIcon style={{ float: 'inline-end' }} /> : <ChevronDownIcon style={{ float: 'inline-end' }} />}
            </h2>
          </TouchableOpacity>

          <Collapsible collapsed={isCollapsed}>
            <View className=" flex flex-col p-2 ">
              <View className="flex-row row pl-4">
                <Icon name="filter" className="px-2" size={20} color="black" />
                <Text className="font-bold">Filter</Text>
              </View>

              <View className="flex-row row place-content-center text-center justify-center m-2">
                <TouchableOpacity className="border border-solid border-black rounded-lg"
                  style={{
                    borderWidth: 1,
                    marginHorizontal: 4,
                  }}
                  onPress={() => setSortOption('a-z')} >
                  <Text className="p-2" >A - Z</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  style={{
                    borderWidth: 1,
                    marginHorizontal: 4,
                  }} 
                  className="border border-solid border-black rounded-lg" onPress={() => setSortOption('z-a')} >
                  <Text className="p-2">Z - A</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  style={{
                    borderWidth: 1,
                    marginHorizontal: 4,
                  }} 
                  className="border border-solid border-black rounded-lg" onPress={() => setSortOption('price-asc')} >
                  <Text className="p-2">Price: Asc</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  style={{
                    borderWidth: 1,
                    marginHorizontal: 4,
                  }} 
                  className="border border-solid border-black rounded-lg" onPress={() => setSortOption('price-desc')} >
                  <Text className="p-2" >Price: Desc</Text>
                </TouchableOpacity>
              </View>
            </View>

            {sortedMenuItems ? (
              sortedMenuItems.map((menuItem) => (
                <MenuItemCard
                  key={menuItem.id}
                  id={menuItem.id}
                  imgSrc={menuItem.menuItemImage}
                  address={""}
                  title={menuItem.name}
                  dishes={""}
                  description={menuItem.description}
                  basePrice={menuItem.basePrice}
                />
              ))
            ) : (
              <Loader />
            )}
          </Collapsible>
        </ScrollView>
      </View>
  ) : (
    <></>
  );
};

export default MenuCategory;
