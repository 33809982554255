import { View, Text, ScrollView } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ArrowRightIcon } from 'react-native-heroicons/outline'
import MenuItemCard from './MenuItemCard'
import axios from 'axios'
import { environment } from '../environment/environment'

import "../styles.css";
import RestaurantCard from './RestaurantCard'
import Loader from './Loader'
import { Header } from '@react-navigation/stack'

const Restaurants = () => {
    const [restaurants, setRestaurants] = React.useState([]);

  useEffect(() => {
    axios.get(environment.apiUrl + 'RestaurantManagement/getallrestaurants')
      .then(response => {
        console.log('Restaurants :');
        console.log(response.data);
        setRestaurants(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <View className="bg-black pt-5">
      <Text>
      <h1 className="text-xl text-white px-4">Restaurants</h1> 
      </Text>   
    <ScrollView
      className="bg-black pt-5"
    >
      {restaurants.length == 0? 
      <Loader/>
      : restaurants?.map(restaurant => (
        <RestaurantCard
          key={restaurant.id}
          id={restaurant.id}
          imgSrc={restaurant.featuredImage}
          address={''}
          title={restaurant.name}
          dishes={''}
          short_description={restaurant.description}
        />
      ))}

    </ScrollView>
    </View>
  )
}

export default Restaurants